.goods_group > h2 {
  margin-top: 20px;
  font-size: 30px;
  line-height: 38px;
}

.goods_group .goods_container {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 40px 10px;
  padding: 0 5px;
}

.goods_group .goods_container_small {
  margin-top: 17px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.goods_group .show_all {
  margin-top: 58px;
  text-align: center;
}

.goods_group .show_all a {
  font-weight: 500;
  line-height: 18px;
  color: #000;
  text-decoration: none;
  background-color: white;
  padding: 19px  108px;
  border-radius: 12px;
}

.goods_group .show_all .arrow {
  margin-left: 14px;
}

@media (max-width: 1439px) {
  .goods_group .goods_container {
    grid-template-columns: repeat(4, 1fr);
  }

  .goods_group .goods_container > *:nth-child(n+13) {
    display: none;
  }
}

@media (max-width: 1199px) {
  .goods_group .goods_container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 991px) {
  .goods_group > h2 {
    margin-left: 10px;
  }
}

@media (max-width: 768px) {
  .goods_group .goods_container {
    grid-template-columns: repeat(2, 1fr);
  }

  .goods_group .goods_container > *:nth-child(n+11) {
    display: none;
  }
}

@media (max-width: 575px) {
  .goods_group > h2 {
    font-size: 24px;
    line-height: 28px;
  }

  .goods_group .show_all a {
    padding: 24px  70px;

  }
}
